import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

let randI = (i) => Math.floor(i * Math.random())
let rand = (arr) => arr[randI(arr.length)]

let links = [
  { title: "DOM10", link: ['/dom10'] },
  { title: "DOM9", link: ['/dom9'] },
  { title: "DOM8", link: ['/dom8'] },
  { title: "DOM7", link: ['/dom7'] },
  { title: "DOM6", link: ['/dom6'] },
  { title: "DOM5", link: ['/dom5'] },
  { title: "DOM4", link: ['/dom4'] },
  { title: "DOM3", link: ['/dom3'] },
  { title: "DOM2", link: ['/dom2'] },
  { title: "DOM1", link: ['/dom1'] },
  
  {
    title: "basil", link: [
      'https://gateway.fxhash2.xyz/ipfs/QmZjP7iWwgtqJ8AAmdD6wxVS9ATmScZyh2WSz6FmQzd7r3/?fxhash=ooCfbPHD7jwwZ5Rn7FB53YZnwSECe9ib4PgRxcfLqnocEXbozen&fxchain=TEZOS',
      'https://gateway.fxhash2.xyz/ipfs/QmZjP7iWwgtqJ8AAmdD6wxVS9ATmScZyh2WSz6FmQzd7r3/?fxhash=onv3cA6d5hjs98XaFt4gn36AWAHF9rdDs4SoifJ6y3xuCRY7svG&fxchain=TEZOS&fxiteration=183&fxminter=tz1fn1T57h9icKEeG97XtymQ2AJjVY56p9oN&fxwalletsowner=tz1PE415ckPNF9SD9VrxT2MgL8fpwXSk9F1k',
      'https://gateway.fxhash2.xyz/ipfs/QmZjP7iWwgtqJ8AAmdD6wxVS9ATmScZyh2WSz6FmQzd7r3/?fxhash=opWD9dWoxxRLvpT5nDeo5RGj65Xc5zq5VJJw9ZZ1ZUWBu49RQkQ&fxchain=TEZOS&fxiteration=112&fxminter=tz1PZ5ahr8JFLmuvMFzNj72odJ4Ei462ZUip&fxwalletsowner=tz1PZ5ahr8JFLmuvMFzNj72odJ4Ei462ZUip',
      'https://gateway.fxhash2.xyz/ipfs/QmZjP7iWwgtqJ8AAmdD6wxVS9ATmScZyh2WSz6FmQzd7r3/?fxhash=ooA8dbEgRs1uhJBsRgXsMewoFBiuQYi3BmgifxQrkVkQbST84Df&fxchain=TEZOS&fxiteration=162&fxminter=tz1ZU5N4UHW519RtpyB7yZdETEWBtGRpmA3W&fxwalletsowner=tz1X8ULzCNgeqzy2L8P34TahS2f85Nk1RxCt',
      'https://gateway.fxhash2.xyz/ipfs/QmZjP7iWwgtqJ8AAmdD6wxVS9ATmScZyh2WSz6FmQzd7r3/?fxhash=ooYkvcioJquoMTHudMxEX1pRNVrbcv3ssV3wcz29K1sDc6Vn8xj&fxchain=TEZOS&fxiteration=215&fxminter=tz1PnoxETN2uVxkoca6SgMPvhFzjtwBwhXAs&fxwalletsowner=tz1XHADaUcMSkTN9gdmtRqcnrrZfs4tNkCPg'
    ]
  },
  {
    title: "sigmas fort", link: [
      'https://gateway.fxhash2.xyz/ipfs/QmVC1Z5gosGHJJqCoqfavXfBUXVRiK9KnWA7C4sQv42mGp/?fxhash=ooZ1FGUb36jxzTBow9NMmRZYhC8SYt3ec6qxzkSn4VN3JxUZUsS&fxchain=TEZOS&fxiteration=15&fxminter=tz1MkNqnXnYb2Wc9VcUSfpbWcv7n7kMDMizS&fxwalletsowner=tz1MkNqnXnYb2Wc9VcUSfpbWcv7n7kMDMizS&fxwalletsowner=0xa5f2bB90AE6A16486D7D33d2A8A654da93fC70cF',
      'https://gateway.fxhash2.xyz/ipfs/QmVC1Z5gosGHJJqCoqfavXfBUXVRiK9KnWA7C4sQv42mGp/?fxhash=oo3fibtzESANiPTQ52VdSpimscbAT2ziAMSYeafmz5MvzPn5um8&fxchain=TEZOS',
      'https://gateway.fxhash2.xyz/ipfs/QmVC1Z5gosGHJJqCoqfavXfBUXVRiK9KnWA7C4sQv42mGp/?fxhash=ootBcGvW7HtLGAbVKwsvf3omSD8Z6BxAd8A5NyquCLa4vbkvz9W&fxchain=TEZOS&fxiteration=130&fxminter=tz1a84jgwATwyYScCS4gNWZ4rcXacBLg4qT2&fxwalletsowner=tz1a84jgwATwyYScCS4gNWZ4rcXacBLg4qT2',
    ]
  },
  { title: "Ant", link: ['/antboyy'] },
  {
    title: "shiso", link: [
      'https://gateway.fxhash2.xyz/ipfs/QmUKe1U7HNnwBxymGXZ4nRh81q81JYQieNMJgLFKsyQar9/?fxhash=oo29o3eVztLqx19tZLtVtYrMVLezGdHCtfgxWjJWCFsFdoAL7fA&fxchain=TEZOS',
      'https://gateway.fxhash2.xyz/ipfs/QmUKe1U7HNnwBxymGXZ4nRh81q81JYQieNMJgLFKsyQar9/?fxhash=ooNhBo2uZaynLWMPNwzxtjMGRg86pV6ZKkd1UusGs4VKhVd6xwi&fxchain=TEZOS&fxiteration=9&fxminter=tz1XMdM5iv4mQSP7b442MufMRvKci74QgTkf&fxwalletsowner=tz1aLRgpZN5ZuT7rNJ1yKJgijKJH3N84E2x7',
      'https://gateway.fxhash2.xyz/ipfs/QmUKe1U7HNnwBxymGXZ4nRh81q81JYQieNMJgLFKsyQar9/?fxhash=ooqYkPRWhyfcrmnJNFVL8jDFYGWoRHiJyFDcpbkJSTA7X1dV4gB&fxchain=TEZOS&fxiteration=118&fxminter=tz1TEL6xFJx1hJKukx21NCufAfGEEr9yYoKX&fxwalletsowner=tz1TEL6xFJx1hJKukx21NCufAfGEEr9yYoKX',
      'https://gateway.fxhash2.xyz/ipfs/QmUKe1U7HNnwBxymGXZ4nRh81q81JYQieNMJgLFKsyQar9/?fxhash=onvttCd4nfehMLpnVTuLCjyxLUMNowJr5rLFFP9XM8ra2gCXeku&fxchain=TEZOS&fxiteration=105&fxminter=tz1LCVV5ESoDg6kAKbJc7JVdtKv5DWkAxUuv&fxwalletsowner=tz1LCVV5ESoDg6kAKbJc7JVdtKv5DWkAxUuv',
      'https://gateway.fxhash2.xyz/ipfs/QmUKe1U7HNnwBxymGXZ4nRh81q81JYQieNMJgLFKsyQar9/?fxhash=oo29wuz7HeqbeegKDYdN4JGu1TJXpsTPv9Qeg8QR85uJx2GWL3U&fxchain=TEZOS&fxiteration=175&fxminter=tz1NABW5hTM33gVgPjMHmYgaLdKoGvaek7FQ&fxwalletsowner=tz1NABW5hTM33gVgPjMHmYgaLdKoGvaek7FQ',
      'https://gateway.fxhash2.xyz/ipfs/QmUKe1U7HNnwBxymGXZ4nRh81q81JYQieNMJgLFKsyQar9/?fxhash=ootWH8Ro5d2HkeHFnrFB5pv6fzR4A8vxgSHDZb2ksxwhRsnaDEo&fxchain=TEZOS&fxiteration=434&fxminter=tz1P4iM5y89uzQomRZPZ7XFuHV6Uw3b7YTQ9&fxwalletsowner=tz1P4iM5y89uzQomRZPZ7XFuHV6Uw3b7YTQ9&fxwalletsowner=0xC9d25B9A3496c776688833D6cCfE507Ef4f41645',
    ]
  },

  { title: "love is", link: ['/loveis'] },
  { title: "looperbob", link: ['/looperboy'] },
  {
    title: "deerhunter", link: [
      'https://gateway.fxhash2.xyz/ipfs/QmfVUuc4LUJ3i8bGcCcZCigvTAsAQMLgzUo8NyFWc1Yqxa/?fxhash=op2J3ytfHBNJbw2rMYuyQpQjcznDKBhKFYSBtHMqi5ct5s8wWcp&fxchain=TEZOS&fxiteration=114&fxminter=tz1MEnyqF75TB7zUQxEBSZ5a6S9A72CLXkF5&fxwalletsowner=tz1MEnyqF75TB7zUQxEBSZ5a6S9A72CLXkF5&fxwalletsowner=0x04114a187691D8f839AEfAFB0766377606347580',
      'https://gateway.fxhash2.xyz/ipfs/QmfVUuc4LUJ3i8bGcCcZCigvTAsAQMLgzUo8NyFWc1Yqxa/?fxhash=ooVAazrNaT5znJ6Atf9JitTV3im3Qt1hCvcGLvNTgEEWEAHFtWn&fxchain=TEZOS&fxiteration=1&fxminter=tz1HJ3Dm52bG1BgHKXhSJEnhYC7g24QE5eWx',
      'https://gateway.fxhash2.xyz/ipfs/QmfVUuc4LUJ3i8bGcCcZCigvTAsAQMLgzUo8NyFWc1Yqxa/?fxhash=ooW8QsPuti2nASZXcjsyPFgjBYXpGFxU1mJUK95KFdFwDU31iHH&fxchain=TEZOS&fxiteration=33&fxminter=tz1cnyX1CUqm9zRsZSaN49dSF51iBwnfoFah&fxwalletsowner=tz1cnyX1CUqm9zRsZSaN49dSF51iBwnfoFah&fxwalletsowner=0x40cF19158b7104298527db2090Ce88c783c7565d'
    ]
  },
  {
    title: "edit ", link: [
      'https://gateway.fxhash2.xyz/ipfs/QmdvtCRC5qUMHRJR6bzKoSwcuanMjKbGdzeV5sWaKp8DCp/?fxhash=opT5xwzjL5GhzWhsUzfrkgdRRvAggeoHsHdYjxq5aWiKPotBLYR&fxchain=TEZOS&fxiteration=41&fxminter=tz1dFcfEbAb9abXBEXERV4uMv48e4x17MuBb&fxwalletsowner=tz1dFcfEbAb9abXBEXERV4uMv48e4x17MuBb',
      'https://gateway.fxhash2.xyz/ipfs/QmdvtCRC5qUMHRJR6bzKoSwcuanMjKbGdzeV5sWaKp8DCp/?fxhash=oobjAGyiNcCK7HcZCwQVP8c1D6uc1H5u1sPeNr9s9cgYUv9tkbJ&fxchain=TEZOS',
      'https://gateway.fxhash2.xyz/ipfs/QmdvtCRC5qUMHRJR6bzKoSwcuanMjKbGdzeV5sWaKp8DCp/?fxhash=onmYMc4utZHrdKvjuqGmMvey6sjYH19gD8y8pwbWCHkWsfTsxjT&fxchain=TEZOS&fxiteration=19&fxminter=tz1SLRzGqX9fuKPx1PAkrDxCvaetr524is11&fxwalletsowner=tz1SLRzGqX9fuKPx1PAkrDxCvaetr524is11&fxwalletsowner=0x4EC5B2606aC7d20B1b0030D156F6D789b5873ABD'
    ]
  },
  {
    title: "disk 2", link: [
      'https://gateway.fxhash2.xyz/ipfs/Qma6AgWkVy8arNSk2An3QUtYWYiUxrKXofzmjzdvQMPSiy/?fxhash=oo1RZwySdCPTq64TrSWRv64Spib2H85Ns2hCwYGHT3sL1Ye5UxX&fxchain=TEZOS',
      'https://gateway.fxhash2.xyz/ipfs/Qma6AgWkVy8arNSk2An3QUtYWYiUxrKXofzmjzdvQMPSiy/?fxhash=oo93uJPNS7bSzngAga6A5nkhWdXLGSsmVdU86y4SrBfhmGsPZkG&fxchain=TEZOS&fxiteration=14&fxminter=tz1hs5myYTELiowX2SeiL19mxJWugfjYfwPU&fxwalletsowner=tz1hs5myYTELiowX2SeiL19mxJWugfjYfwPU',
      'https://gateway.fxhash2.xyz/ipfs/Qma6AgWkVy8arNSk2An3QUtYWYiUxrKXofzmjzdvQMPSiy/?fxhash=oozYorddxPepCUfvrjtopKmGe9Uk9Dm7XnfteAUwUAUZU82os4v&fxchain=TEZOS&fxiteration=163&fxminter=tz1Az8X1RFbLHx37drUAETV1wyQkXXdSgUZW'
    ]
  },
  // {
  //   title: "disk", link: [
  //     'https://gateway.fxhash2.xyz/ipfs/QmeQPN3qtydDKGN8NdasZ9HTt5no5Q1PuUQoYSkhEAAcnz/?fxhash=oou1hh23ZgosL7fNEF7ysC9hE7mHwsUCfpRgktprCeHoBcPmmy2&fxchain=TEZOS',
  //     'https://gateway.fxhash2.xyz/ipfs/QmeQPN3qtydDKGN8NdasZ9HTt5no5Q1PuUQoYSkhEAAcnz/?fxhash=oouFUaH7DbDoAsyxbtbj3f1ArAPoZxa73R7VVw68fdagJZ67FjV&fxchain=TEZOS&fxiteration=103&fxminter=tz1QS5LWkAe7RHUKX7ByqSzLwQVHAvHh6MD5',
  //     'https://gateway.fxhash2.xyz/ipfs/QmeQPN3qtydDKGN8NdasZ9HTt5no5Q1PuUQoYSkhEAAcnz/?fxhash=onnqCo7fnsE8zcY19kMV3CuNTvL1w2zMJ6hnhnhAQgto6r9qZui&fxchain=TEZOS&fxiteration=67&fxminter=tz1YwUaCvLcXUCTgbbHeg9pKiLPqMmFF5osX&fxwalletsowner=tz1YwUaCvLcXUCTgbbHeg9pKiLPqMmFF5osX&fxwalletsowner=0x2fe815AE662c439B5D33e712D0b2AC1B47FA2567',
  //   ]
  // },
  // {
  //   title: "jazzdans", link: [
  //     'https://gateway.fxhash2.xyz/ipfs/QmR1dBDtrVNxWzaNwiNWVuPQCZnUYG2kULhxw5bhs3EDuC/?fxhash=onxJ6athayx8pLNYSGYP16ny5ygTUsmy8gRayoLbpLzfLqkH6um&fxchain=TEZOS&fxiteration=67&fxminter=tz1PFYFQWNFw5tMaAqyECkzCp9gpuUrPgXfA&fxwalletsowner=tz1PFYFQWNFw5tMaAqyECkzCp9gpuUrPgXfA',
  //     'https://gateway.fxhash2.xyz/ipfs/QmR1dBDtrVNxWzaNwiNWVuPQCZnUYG2kULhxw5bhs3EDuC/?fxhash=ook7iQd2NRiC5CimFFjsrsppnLSXR3FHKQ4kgQ3aG52hZUEgDuW&fxchain=TEZOS',
  //     'https://gateway.fxhash2.xyz/ipfs/QmR1dBDtrVNxWzaNwiNWVuPQCZnUYG2kULhxw5bhs3EDuC/?fxhash=op8JGPwZU3NkQLRpoNrLQwhT7YtnHkeKraWg2zaSMr2dTpYawxw&fxchain=TEZOS&fxiteration=128&fxminter=tz1c1GaKWXUizjpz6b3wEzd5ouYBqWgZmrbd&fxwalletsowner=tz1c1GaKWXUizjpz6b3wEzd5ouYBqWgZmrbd&fxwalletsowner=0x472723a705B7FdF72c92B97696f26DF545EC11F1',
  //   ]
  // },
  // {
  //   title: "meios", link: [
  //     'https://gateway.fxhash2.xyz/ipfs/QmNXsJCEkw96k7BrNM9odDRhEAdgJepLbwdy7fFArwLA6y/?fxhash=onk3M8ikK8VQVp6tZf4HErGfsGiCj7yaqmSdNqi75PTSojuSGFx&fxchain=TEZOS&fxiteration=29&fxminter=tz1cg3zot4RRvYTjaQNBcsGMJ7JhEkf9bnSv&fxwalletsowner=tz1cg3zot4RRvYTjaQNBcsGMJ7JhEkf9bnSv',
  //     'https://gateway.fxhash2.xyz/ipfs/QmNXsJCEkw96k7BrNM9odDRhEAdgJepLbwdy7fFArwLA6y/?fxhash=ooNYg6SirGAHFpxVUETYSezzHdvK9B468X56KNa35EL8pi4uAvS&fxchain=TEZOS',
  //     'https://gateway.fxhash2.xyz/ipfs/QmNXsJCEkw96k7BrNM9odDRhEAdgJepLbwdy7fFArwLA6y/?fxhash=oonajZ6WRhdDNnV5wJYs6ic5zrMLvN3Z8vhcwtvnqFMezEFcdYL&fxchain=TEZOS&fxiteration=140&fxminter=tz1hk9DcELXzt24jK6Dh3RkFkZHsQ95Jzaas&fxwalletsowner=tz1hk9DcELXzt24jK6Dh3RkFkZHsQ95Jzaas&fxwalletsowner=0x7Ba3D1C4F46516fb975FB012F7db04381188e907',
  //     'https://gateway.fxhash2.xyz/ipfs/QmNXsJCEkw96k7BrNM9odDRhEAdgJepLbwdy7fFArwLA6y/?fxhash=onpADaPcQ3dt8VdMzRRmLER2gjk6r6rwBuH64VvJFKXzqjhwF7n&fxchain=TEZOS&fxiteration=30&fxminter=tz1WEgnR7fKQM78ttbCnQvBw87QfM4Jimnkz&fxwalletsowner=tz1g6RchtW9x4hRNR2JXJ67rpoAQ6gXUpAdo',
  //     'https://gateway.fxhash2.xyz/ipfs/QmNXsJCEkw96k7BrNM9odDRhEAdgJepLbwdy7fFArwLA6y/?fxhash=ooTH25HXxeXLVot5L5kkLhCM7bxez9mPJ8ra3rizptBTaDZQiUR&fxchain=TEZOS&fxiteration=25&fxminter=tz1hvfkpf7HbnE1Rroi7JbyegVjZzu97Yqw6&fxwalletsowner=tz1NNJCiknQUowNe7ikYYEy1X6XTGaKbHxdn',
  //     'https://gateway.fxhash2.xyz/ipfs/QmNXsJCEkw96k7BrNM9odDRhEAdgJepLbwdy7fFArwLA6y/?fxhash=ooTH25HXxeXLVot5L5kkLhCM7bxez9mPJ8ra3rizptBTaDZQiUR&fxchain=TEZOS&fxiteration=25&fxminter=tz1hvfkpf7HbnE1Rroi7JbyegVjZzu97Yqw6&fxwalletsowner=tz1NNJCiknQUowNe7ikYYEy1X6XTGaKbHxdn',
  //   ]
  // },

  {
    title: "birds", link: [
      'https://gateway.fxhash2.xyz/ipfs/QmdbkhiGqznPJG2MP4u5WEVR36J3sqVScNC5TmAQTfmawR/?fxhash=oo76LC4rdrCZqHCT5DksNxmGhg4b9MhcX5txZ3GjFGYk5Lubnek&fxchain=TEZOS',
      'https://gateway.fxhash2.xyz/ipfs/QmdbkhiGqznPJG2MP4u5WEVR36J3sqVScNC5TmAQTfmawR/?fxhash=oo4KjVESitffUYa4su9zWE5zwV6U8FRdRfbHPkRDqVoM55ADnEj&fxchain=TEZOS&fxiteration=8&fxminter=tz1fgqyqxfWK3FSWbrb1KfH9257am4fZmsqb&fxwalletsowner=tz1WCZKBLKeRspbg8EwL7jzQYZW1gtaK6inU',
      'https://gateway.fxhash2.xyz/ipfs/QmdbkhiGqznPJG2MP4u5WEVR36J3sqVScNC5TmAQTfmawR/?fxhash=opEHVrrm9orymnbVGC63KeKhGPq19XPbMBGtW2y8u6HEjcRWyp5&fxchain=TEZOS&fxiteration=41&fxminter=tz1TEL6xFJx1hJKukx21NCufAfGEEr9yYoKX&fxwalletsowner=tz1TEL6xFJx1hJKukx21NCufAfGEEr9yYoKX',
      'https://gateway.fxhash2.xyz/ipfs/QmdbkhiGqznPJG2MP4u5WEVR36J3sqVScNC5TmAQTfmawR/?fxhash=opPDYrVSTvSKjesgrMXF6b3swhnYBwumsSMoNWotKPV4BVytokY&fxchain=TEZOS&fxiteration=77&fxminter=tz1VGZPvqytFVAoSAsM8VrUveJdL3PpVy6K9&fxwalletsowner=tz1Xb2K313xkeVFMtWFNEgSgANSTKxLM4BqP&fxwalletsowner=0xB1008E36D294C236BE3C7C95E10Ce4c6242cCD5d',
      'https://gateway.fxhash2.xyz/ipfs/QmdbkhiGqznPJG2MP4u5WEVR36J3sqVScNC5TmAQTfmawR/?fxhash=opA5u2nd35nFqKEBMBVciPxM7m4UuDxJaaKsCkX3wX6iPTurR65&fxchain=TEZOS&fxiteration=28&fxminter=tz1bThk6y2JUuuUvsUCgjLsGWFDqAf7r29vL&fxwalletsowner=tz1aHAsDofQd9KQb9ZsFZUzAsu39tJ2JrmKD',
      'https://gateway.fxhash2.xyz/ipfs/QmdbkhiGqznPJG2MP4u5WEVR36J3sqVScNC5TmAQTfmawR/?fxhash=oomb49S9PqiwnVjr9K1tz4AANLa6XSwBJnWvit5s9QnidR4Z8XF&fxchain=TEZOS&fxiteration=84&fxminter=tz1MvJFJnoEASM4Qpn59xB2ux6CWKzFeYKNt&fxwalletsowner=tz1MvJFJnoEASM4Qpn59xB2ux6CWKzFeYKNt',
      'https://gateway.fxhash2.xyz/ipfs/QmdbkhiGqznPJG2MP4u5WEVR36J3sqVScNC5TmAQTfmawR/?fxhash=ooKSXDqKktVmBnsvPX8T4NVBHM4BDzLigpy1MAVm9E7sw2ESjrv&fxchain=TEZOS&fxiteration=115&fxminter=tz1PK76AxDn23zWswVJFdYjJjwuQNbNfi4N8&fxwalletsowner=tz1PFYFQWNFw5tMaAqyECkzCp9gpuUrPgXfA',
      'https://gateway.fxhash2.xyz/ipfs/QmdbkhiGqznPJG2MP4u5WEVR36J3sqVScNC5TmAQTfmawR/?fxhash=oo2pBriycDdhYxY77hN19r7aqrFrHLSxkmidwGUXYKCnoXXkcJw&fxchain=TEZOS&fxiteration=97&fxminter=tz1Y913cjeoLFXuz6Uzqq54A6vshAeQd9CiE&fxwalletsowner=tz1gjftp2aoEkC4xvRfNNkPjcfF2fStESgwo&fxwalletsowner=0x93972484E68415B85B3aFf169b772799E4BAca16',
    ]
  },
  // {
  //   title: "bubblish ", link: [
  //     'https://gateway.fxhash2.xyz/ipfs/QmNrZX6mGAGkKQ2yYzo1So2jznZBEKTeu5Y58me59AKfSA/?fxhash=oo5uoo214UaAJ4zqsHhYBXkNPc5XB6Sn2j4ugr6H8FnbSSjSX7o&fxchain=TEZOS',
  //     'https://gateway.fxhash2.xyz/ipfs/QmNrZX6mGAGkKQ2yYzo1So2jznZBEKTeu5Y58me59AKfSA/?fxhash=oodZz2R6v85VPEmEDqREXYJLwn923ntourbXGHCeZYdaMQpKKNo&fxchain=TEZOS&fxiteration=10&fxminter=tz1UmMwUrDsYoWMJYGvrhkUrgJeibp4ba6Eo&fxwalletsowner=tz1UmMwUrDsYoWMJYGvrhkUrgJeibp4ba6Eo',
  //     'https://gateway.fxhash2.xyz/ipfs/QmNrZX6mGAGkKQ2yYzo1So2jznZBEKTeu5Y58me59AKfSA/?fxhash=opEFRKyeDHmff2Pz67cyr9rvXBcn2gMKtaeueZYwoguYS2oLDrC&fxchain=TEZOS&fxiteration=35&fxminter=tz1c1GaKWXUizjpz6b3wEzd5ouYBqWgZmrbd&fxwalletsowner=tz1ZhXTogdFtGzYbL6d9Z13ipqNMcnfTB6MZ&fxwalletsowner=0xb2d866297dbC7C7d8Ad045c05AFB48A95f28fB3C',
  //     'https://gateway.fxhash2.xyz/ipfs/QmNrZX6mGAGkKQ2yYzo1So2jznZBEKTeu5Y58me59AKfSA/?fxhash=onuAUfrRxezmfyghDwYU66HC2fXM8ivBGqjguw7aVWoBkxioypV&fxchain=TEZOS&fxiteration=78&fxminter=tz1hkKqGk3fPPYroNagWpWsxxV3x8P8jqwp9&fxwalletsowner=tz1hkKqGk3fPPYroNagWpWsxxV3x8P8jqwp9',
  //     'https://gateway.fxhash2.xyz/ipfs/QmNrZX6mGAGkKQ2yYzo1So2jznZBEKTeu5Y58me59AKfSA/?fxhash=opHhiDr3XUUPLVjrydxnqfnTZSJ8FcZnLsxk5ZnH5itaghQBNBP&fxchain=TEZOS&fxiteration=138&fxminter=tz1YWKuEKfVZ7dM2XgG1eKJyZa27QzJhV485&fxwalletsowner=tz1YWKuEKfVZ7dM2XgG1eKJyZa27QzJhV485',
  //     'https://gateway.fxhash2.xyz/ipfs/QmNrZX6mGAGkKQ2yYzo1So2jznZBEKTeu5Y58me59AKfSA/?fxhash=oo2cQsizmkP3H49fziGTGLvoQDFrpcPhnetgJUxTxsVvNKxZKTU&fxchain=TEZOS&fxiteration=73&fxminter=tz1eCcYKhRQtTANR6jeHBCiptzRyyZtUvpbg&fxwalletsowner=tz1eCcYKhRQtTANR6jeHBCiptzRyyZtUvpbg&fxwalletsowner=0x6d9A9f6DCfeC8D1B3f67eC8F4044f2e8eF534e0b',
  //     'https://gateway.fxhash2.xyz/ipfs/QmNrZX6mGAGkKQ2yYzo1So2jznZBEKTeu5Y58me59AKfSA/?fxhash=oofKbxLyq15cJianUp5A9s8wUU43f8z2JnS2CYifvZcpyvuLpym&fxchain=TEZOS&fxiteration=70&fxminter=tz1VZrkBPdGjdS1mBHnr6Msu61GszRMGXFYf&fxwalletsowner=tz1VZrkBPdGjdS1mBHnr6Msu61GszRMGXFYf&fxwalletsowner=0x163C58774B6CF4Df98B66Ee3835F41Ba7175F13C',
  //   ]
  // },
  
  // {
  //   title: "vanillla", link: [
  //     'https://gateway.fxhash2.xyz/ipfs/QmaqxKYmyariKNJfxUq4yMPJkNas5v6QRcbBxjtc2VfKVQ/?fxhash=ooaJk3ohmhVnD8SeHX2mCUbdtNWfyCpVy74BViBxp4sPHxd3m3k&fxchain=TEZOS',
  //     'https://gateway.fxhash2.xyz/ipfs/QmaqxKYmyariKNJfxUq4yMPJkNas5v6QRcbBxjtc2VfKVQ/?fxhash=ons5qnCNSm18Fhcnmu86P951VqRZwDPMkGfHvKneW4nodfWiZ5i&fxchain=TEZOS&fxiteration=38&fxminter=tz1SubGoFi5CbN9V5AGoDgz9urUof5skBkxd&fxwalletsowner=tz1SubGoFi5CbN9V5AGoDgz9urUof5skBkxd',
  //   ]
  // },
  {
    title: "timbre", link: [
      'https://gateway.fxhash2.xyz/ipfs/QmVNcUVhUDKS8nBRjoygkf2NpvQzachpqRUf3RafYUth37/?fxhash=onvSnd82RD4K12CnbWQReW384Jq5sit2gatLbqnyLwsy8DdPN3Z&fxchain=TEZOS&fxiteration=31&fxminter=tz1RUacJBfK5kSSLExDdGKdTSHw7gwmNQFoy&fxwalletsowner=tz1RUacJBfK5kSSLExDdGKdTSHw7gwmNQFoy',
      'https://gateway.fxhash2.xyz/ipfs/QmVNcUVhUDKS8nBRjoygkf2NpvQzachpqRUf3RafYUth37/?fxhash=ontnpsC7YG5VVi8thJ7ycgpkVqGQ8GKUNUz4kgagqmhhpnAH9CU&fxchain=TEZOS&fxiteration=16&fxminter=tz1bThk6y2JUuuUvsUCgjLsGWFDqAf7r29vL&fxwalletsowner=tz1aHAsDofQd9KQb9ZsFZUzAsu39tJ2JrmKD',
      'https://gateway.fxhash2.xyz/ipfs/QmVNcUVhUDKS8nBRjoygkf2NpvQzachpqRUf3RafYUth37/?fxhash=ooFKQtxLTTw3LSo9CrYZUUqXNAMeUwm4zNvJFtQLKDuqsoiWQMX&fxchain=TEZOS&fxiteration=15&fxminter=tz1XuYLhHwQHtz93tPuGcLmHS9TvFWNRopjZ&fxwalletsowner=tz1XuYLhHwQHtz93tPuGcLmHS9TvFWNRopjZ&fxwalletsowner=0xE47288C1F31B1ADe8976EFFee096c9BAB0341c38',
      'https://gateway.fxhash2.xyz/ipfs/QmVNcUVhUDKS8nBRjoygkf2NpvQzachpqRUf3RafYUth37/?fxhash=oni2QqhW8qXq4bFg8qns5i9fx2XqjThNabKaKnmkYr24dsXPFXF&fxchain=TEZOS&fxiteration=1&fxminter=tz1he2B6L9MqwUrXPysHro7E5GN1QosVLMad&fxwalletsowner=tz1he2B6L9MqwUrXPysHro7E5GN1QosVLMad&fxwalletsowner=0xC6Dd8fC985F028e7E8A601fD40B1Fd36b4e502de',
    ]
  },
  {
    title: "tug-o-war", link: [
      'https://gateway.fxhash2.xyz/ipfs/QmR95W7UpP5Gbc8RiUh1CdSySRy14RCjgjDZe3Q1g6AxZ6/?fxhash=ooNr7HqvJM1q4rPXiUK6WTv5MVRWVBGHgv3kWwNaVai6m5R9D1n&fxchain=TEZOS',
      'https://gateway.fxhash2.xyz/ipfs/QmR95W7UpP5Gbc8RiUh1CdSySRy14RCjgjDZe3Q1g6AxZ6/?fxhash=opKYDMcvyy3FjBKCs8asideg58FMNSnTtfdj4XmfjpUqK4bKRAk&fxchain=TEZOS&fxiteration=4&fxminter=tz1XpFAeKEyiNf3GcyhwdPZHiJ8BuwK9APvX&fxwalletsowner=tz1XpFAeKEyiNf3GcyhwdPZHiJ8BuwK9APvX&fxwalletsowner=0xfbD7285049834aD3cAA567f17bfA6793d35e7460',
      'https://gateway.fxhash2.xyz/ipfs/QmR95W7UpP5Gbc8RiUh1CdSySRy14RCjgjDZe3Q1g6AxZ6/?fxhash=opHGSh4YwAtE4dDRpYusfBFDAN9P948bFEtX3aXKgBZHDpSMGMR&fxchain=TEZOS&fxiteration=27&fxminter=tz1PPPhYZDEqNL6Cre6XFnGKnnMT3JGjENab&fxwalletsowner=tz1gEVqnejRkwfpfqoxhTfi9tDm1xT2mqiGV&fxwalletsowner=0x4aa93c41dEb2bb0E088b1728e40Db6322b6C3010',
      'https://gateway.fxhash2.xyz/ipfs/QmR95W7UpP5Gbc8RiUh1CdSySRy14RCjgjDZe3Q1g6AxZ6/?fxhash=ooYpNQRpvcaQhpwTKo68FkRRM657FmFLbUQHjgdtViePvCasPUb&fxchain=TEZOS&fxiteration=61&fxminter=tz1TEL6xFJx1hJKukx21NCufAfGEEr9yYoKX&fxwalletsowner=tz1TEL6xFJx1hJKukx21NCufAfGEEr9yYoKX',
      'https://gateway.fxhash2.xyz/ipfs/QmR95W7UpP5Gbc8RiUh1CdSySRy14RCjgjDZe3Q1g6AxZ6/?fxhash=ooB7H9ZEtmYEzBuLkwfwxGrWUYprtKP583LxaJnfv7WdbM8b3Ve&fxchain=TEZOS&fxiteration=82&fxminter=tz1SbFwEWf41UGYNqAfHLoY3bJ42tWRwpR66&fxwalletsowner=tz1MuQ6VBbZ5wiaGS7JdvB22VekJjzxPTL4F',
    ]
  },
  // {
  //   title: "ekolod", link: [
  //     'https://gateway.fxhash2.xyz/ipfs/Qmc9Eq2AQtaAjsGyYkK373ZrpermE5frcDAkpWiB1RBuZr/?fxhash=ooknqBbievHasdBQkh5metUNhkLRazb8LLxFiXT2yG4xvrwjkZY&fxchain=TEZOS',
  //     'https://gateway.fxhash2.xyz/ipfs/Qmc9Eq2AQtaAjsGyYkK373ZrpermE5frcDAkpWiB1RBuZr/?fxhash=ookppY7MS97Vu17stPVKfAZHP5fu6oZsNn3nPzgJrM91oQTHr8f&fxchain=TEZOS&fxiteration=19&fxminter=tz1sm8TVjSrD5B2gDHzwtHQbmZ1eSv5h7HMD',
  //     'https://gateway.fxhash2.xyz/ipfs/Qmc9Eq2AQtaAjsGyYkK373ZrpermE5frcDAkpWiB1RBuZr/?fxhash=ooDtzc1TnSDeu9ygFCpHfAxi9cAtpwh9jiK71Ezyx5nWLDJuMeB&fxchain=TEZOS&fxiteration=11&fxminter=tz1zaZ5ZSoYBCze8EFWdnyVvwNWensTBYnCw',
  //     'https://gateway.fxhash2.xyz/ipfs/Qmc9Eq2AQtaAjsGyYkK373ZrpermE5frcDAkpWiB1RBuZr/?fxhash=ooNNerGmfTVxqf8oDkv5bSrQyu4owHCDvZdQFeA4EL2FbZwM6QD&fxchain=TEZOS&fxiteration=37&fxminter=tz17WPq58EuK3symRMbG8wTrNbR6o8eiqEwq',
  //     'https://gateway.fxhash2.xyz/ipfs/Qmc9Eq2AQtaAjsGyYkK373ZrpermE5frcDAkpWiB1RBuZr/?fxhash=oo8o7WACqMQWpqV3KdrvECqfsAPFSPqt1X6hZsUzaGnDuEdZ5B5&fxchain=TEZOS&fxiteration=4&fxminter=tz1eeCEmxYStnrD5joW2tv74xqdUX2r3jEHd',
  //     'https://gateway.fxhash2.xyz/ipfs/Qmc9Eq2AQtaAjsGyYkK373ZrpermE5frcDAkpWiB1RBuZr/?fxhash=ooQrtAJabeuuJoNbqFTCug7TR9VmsJXcG136z8fRmnvSGUKLVRF&fxchain=TEZOS&fxiteration=19&fxminter=tz1JvNkiZgKhmTX5JFBvdfkDnPZowvLoS9WG',
  //   ]
  // },
  // {
  //   title: "stream", link: [
  //     'https://gateway.fxhash2.xyz/ipfs/QmeP7MV4ebAQjyrVvdvkRryn581AYdkXAQsqucqcGf5U8c/?fxhash=ooYBdNqgLBvoUWKwCsnQdWVH5m5bgHpxPWvYBHbU1d4798pHsoX&fxchain=TEZOS',
  //     'https://gateway.fxhash2.xyz/ipfs/QmeP7MV4ebAQjyrVvdvkRryn581AYdkXAQsqucqcGf5U8c/?fxhash=opTmKXbAuuyeRSVdSGrzx7eur5q4Wn4Q93P6eDNp1mfuweUCU7e&fxchain=TEZOS&fxiteration=6&fxminter=tz1LnqvCxabMfhfoqwYcC5ZzefV4VNuj58a2&fxwalletsowner=tz1icaK5Va4HFBERFVCkBKZ8QMRL25aEBzBP',
  //     'https://gateway.fxhash2.xyz/ipfs/QmeP7MV4ebAQjyrVvdvkRryn581AYdkXAQsqucqcGf5U8c/?fxhash=onnLdVU1LicafdowgkBXbzfiUSBJGJJqLdiMArvuFNsgUwigqu6&fxchain=TEZOS&fxiteration=152&fxminter=tz1fJsoiGb2xsu9PtbTUdctbnrvXfEQtM6wf&fxwalletsowner=tz1fJsoiGb2xsu9PtbTUdctbnrvXfEQtM6wf',
  //     'https://gateway.fxhash2.xyz/ipfs/QmeP7MV4ebAQjyrVvdvkRryn581AYdkXAQsqucqcGf5U8c/?fxhash=ooEvmGy9KydLkeQ5tsaRZoCRW9JZ3acdMS1WLAjRHyQhWiqA4vf&fxchain=TEZOS&fxiteration=169&fxminter=tz1SvJbECAva9gxczfBmX3CAFUszgQXNgsNm&fxwalletsowner=tz1SvJbECAva9gxczfBmX3CAFUszgQXNgsNm',
  //   ]
  // },
 
  {
    title: "phases 2", link: [
      'https://gateway.fxhash2.xyz/ipfs/QmUPwpGDneJZvfk4ih6qeydxcikLDFWwRSGr74e1G4aEre/?fxhash=ooQEXjUmLvrLps5h7GCec4uG1oCuLFfk1H7i1si4jNa23bM21UJ&fxchain=TEZOS',
      'https://gateway.fxhash2.xyz/ipfs/QmUPwpGDneJZvfk4ih6qeydxcikLDFWwRSGr74e1G4aEre/?fxhash=oonRPGR2BWdAfnH9sjPjANWBA5RU8XWmqvSEQczTTJcitpofS8T&fxchain=TEZOS&fxiteration=16&fxminter=tz1hyZs4vs92L2cE6bnhyVCwU72hreHG4WHn&fxwalletsowner=tz1hyZs4vs92L2cE6bnhyVCwU72hreHG4WHn',
      'https://gateway.fxhash2.xyz/ipfs/QmUPwpGDneJZvfk4ih6qeydxcikLDFWwRSGr74e1G4aEre/?fxhash=ooeHiZf4gi49B1eQh9dTmiFoHAwT5mbBoDk6JfQPAgNcRPsshHn&fxchain=TEZOS&fxiteration=33&fxminter=tz1Mdpj1npkpaxPHF2wFN6oX8SovVPfHyXSy&fxwalletsowner=tz1Mdpj1npkpaxPHF2wFN6oX8SovVPfHyXSy',
      'https://gateway.fxhash2.xyz/ipfs/QmUPwpGDneJZvfk4ih6qeydxcikLDFWwRSGr74e1G4aEre/?fxhash=ooeHiZf4gi49B1eQh9dTmiFoHAwT5mbBoDk6JfQPAgNcRPsshHn&fxchain=TEZOS&fxiteration=33&fxminter=tz1Mdpj1npkpaxPHF2wFN6oX8SovVPfHyXSy&fxwalletsowner=tz1Mdpj1npkpaxPHF2wFN6oX8SovVPfHyXSy',
      'https://gateway.fxhash2.xyz/ipfs/QmUPwpGDneJZvfk4ih6qeydxcikLDFWwRSGr74e1G4aEre/?fxhash=oo9sEwNBtekVvd7ED5xkh76xLFeucGgEZ8enzp4TtQB1eLFLtVS&fxchain=TEZOS&fxiteration=105&fxminter=tz1PSt5Sc8xXGKZxC7UGbp64UevSWnVMrojC&fxwalletsowner=tz1PSt5Sc8xXGKZxC7UGbp64UevSWnVMrojC&fxwalletsowner=0x53F459607b84f60787F5b7C288e8E440439ecB12',
      'https://gateway.fxhash2.xyz/ipfs/QmUPwpGDneJZvfk4ih6qeydxcikLDFWwRSGr74e1G4aEre/?fxhash=onmP5N6dEsfKzKCVw29cEUe3teWqokPtQsYNxzCrZGcuqsddMGF&fxchain=TEZOS&fxiteration=52&fxminter=tz1Pz8bcLNsunzVzgDbALaze65N52LCTTqqK&fxwalletsowner=tz1YxyLY6xsM1swhJi6yQWA4rBmzAJuRDM9J',
      'https://gateway.fxhash2.xyz/ipfs/QmUPwpGDneJZvfk4ih6qeydxcikLDFWwRSGr74e1G4aEre/?fxhash=opaheEvC9Jn5t5RYRQWx6oPBwUrhMC1yD4tuoMNrumz2vx4Mzoh&fxchain=TEZOS&fxiteration=147&fxminter=tz1dnkBqmKSKjSYaP8NuqjtCs9K39ExPgdTG&fxwalletsowner=tz1dnkBqmKSKjSYaP8NuqjtCs9K39ExPgdTG',
      'https://gateway.fxhash2.xyz/ipfs/QmUPwpGDneJZvfk4ih6qeydxcikLDFWwRSGr74e1G4aEre/?fxhash=oooB3H3cmxQxRPk5FyJpLMhYTjnRYy2STEGumkVXqkjfc7awUFt&fxchain=TEZOS&fxiteration=13&fxminter=tz1iVwgzR6F24uwUBuXiAsAynsdwWsgZ9uhK&fxwalletsowner=tz1iVwgzR6F24uwUBuXiAsAynsdwWsgZ9uhK',
    ]
  },
  // {
  //   title: "phases", link: [
  //     'https://gateway.fxhash2.xyz/ipfs/QmS5f9WeTtXDKBXKbDuJgLem1VHmEpZffnJx7UogMkidYg/?fxhash=onjjRL3ef67ABzcow4bTqqSv45aXFRmwew21HRPHoncKnoARk1H&fxchain=TEZOS&fxiteration=10&fxminter=tz1he2B6L9MqwUrXPysHro7E5GN1QosVLMad&fxwalletsowner=tz1he2B6L9MqwUrXPysHro7E5GN1QosVLMad&fxwalletsowner=0xC6Dd8fC985F028e7E8A601fD40B1Fd36b4e502de',
  //     'https://gateway.fxhash2.xyz/ipfs/QmS5f9WeTtXDKBXKbDuJgLem1VHmEpZffnJx7UogMkidYg/?fxhash=ooY2AEkw4KVchbMf2rDyrSBc6NgxrWsTECc8jzQPZ6x9WUy5M48&fxchain=TEZOS&fxiteration=38&fxminter=tz1Q7K3EzaVs5Xw2ifgviNbVReEhf9AQuxpV&fxwalletsowner=tz1Q7K3EzaVs5Xw2ifgviNbVReEhf9AQuxpV&fxwalletsowner=0x7de797Aa875cD2ee938B3bD76926Db43C599836c',
  //     'https://gateway.fxhash2.xyz/ipfs/QmS5f9WeTtXDKBXKbDuJgLem1VHmEpZffnJx7UogMkidYg/?fxhash=ooS8cZ4bSk39zKZ3pDe6eXEvqHFee33i4pF6PnzFXW369p9TijD&fxchain=TEZOS&fxiteration=60&fxminter=tz1WMgiMVxvMyzGE2n19wWHmauAt8uDU9Dw3&fxwalletsowner=tz1WMgiMVxvMyzGE2n19wWHmauAt8uDU9Dw3&fxwalletsowner=0xB8f7F2d9C5Ea31CED0529eF35DB819122621D191',
  //     'https://gateway.fxhash2.xyz/ipfs/QmS5f9WeTtXDKBXKbDuJgLem1VHmEpZffnJx7UogMkidYg/?fxhash=oouFUzkper2Vit5tFmcc2yuUWA2caqruS7S6DkN64cRK98rBK92&fxchain=TEZOS&fxiteration=74&fxminter=tz1bTP9wmc9xwHycfc1PBaMLguSsM2fw4HkM&fxwalletsowner=tz1bTP9wmc9xwHycfc1PBaMLguSsM2fw4HkM',
  //     'https://gateway.fxhash2.xyz/ipfs/QmS5f9WeTtXDKBXKbDuJgLem1VHmEpZffnJx7UogMkidYg/?fxhash=opC4KRpRpNoKNg1sfGeYuoRaw1LL1tpp5dv4GEMhLAFFhpZRw8e&fxchain=TEZOS&fxiteration=42&fxminter=tz1gnYkpAmYQdqfX3JYGYjtgC4sppu4buD6V&fxwalletsowner=tz1gnYkpAmYQdqfX3JYGYjtgC4sppu4buD6V',
  //   ]
  // },
  // {
  //   title: "echoes", link: [
  //     'https://gateway.fxhash2.xyz/ipfs/QmTUm9WtrQpgfwPjXgcs1gxMLKG3wCBvqmNC9SPDevgvdM/?fxhash=ooymeenHtjDd7kVWm5FhPm4FKNAcoELHPE7AcXQWexAzi3umtpV&fxchain=TEZOS&fxiteration=3&fxminter=tz167cX9GyQ7s6SfAs3ujAAJpbnD4DDfCWs2',
  //     'https://gateway.fxhash2.xyz/ipfs/QmTUm9WtrQpgfwPjXgcs1gxMLKG3wCBvqmNC9SPDevgvdM/?fxhash=oo9SupSKDjVGJ538VHEKq2PeEuyDvE6ztT5pvHRJxnMrq3qMbrp&fxchain=TEZOS&fxiteration=6&fxminter=tz15V6ekUGntZ7BH2LeuzEeM328rzy39eHP2',
  //     'https://gateway.fxhash2.xyz/ipfs/QmTUm9WtrQpgfwPjXgcs1gxMLKG3wCBvqmNC9SPDevgvdM/?fxhash=ooiUMu5LZNzno7B8TCb1BHTKSTpFD34CxfQDSzTMkEQUxUc1BaS&fxchain=TEZOS&fxiteration=9&fxminter=tz1dXSoc3DxBkQzy6W3x7Le4fYdyzx1D5qkY',
  //     'https://gateway.fxhash2.xyz/ipfs/QmTUm9WtrQpgfwPjXgcs1gxMLKG3wCBvqmNC9SPDevgvdM/?fxhash=ooqToSTGis4XamPp7oYUJu1Sh6d4avzrChVsT4XS2fsmKSPJ7Z9&fxchain=TEZOS&fxiteration=12&fxminter=tz1vRLw9WX1CsuEi1i6duEuqMkwRysF29413',
  //     'https://gateway.fxhash2.xyz/ipfs/QmTUm9WtrQpgfwPjXgcs1gxMLKG3wCBvqmNC9SPDevgvdM/?fxhash=ood7DoW9jU9EhhMUjALGFQKc9XcfhrC7eykVWLM88jw8ZSgWtJa&fxchain=TEZOS&fxiteration=16&fxminter=tz1NWrZSSb78XrUoZ172NM8Tp4wL1mQS5kzK',
  //     'https://gateway.fxhash2.xyz/ipfs/QmTUm9WtrQpgfwPjXgcs1gxMLKG3wCBvqmNC9SPDevgvdM/?fxhash=ooic39HRjzwpLbBR9Dxs6LEU9S34PecjzHW3YxE1Bb1Mp2ELqoT&fxchain=TEZOS&fxiteration=7&fxminter=tz1pEBNJaqpgFaHgHxR9hsYgAUgusmrnNmtz',
  //     'https://gateway.fxhash2.xyz/ipfs/QmTUm9WtrQpgfwPjXgcs1gxMLKG3wCBvqmNC9SPDevgvdM/?fxhash=ooxrqR9T1cCfYBA4UtQrSfieUYrvniGCAPT3qY6Pp8oxXJcvC4q&fxchain=TEZOS&fxiteration=11&fxminter=tz1pksEAmTMCPTwwejh4duyMhX3L2uUbXTRZ',
  //   ]
  // },
  // {
  //   title: "to air", link: [
  //     'https://gateway.fxhash2.xyz/ipfs/QmQqtmLz4KpjzL6hMatRzgEv9DEmd73L9XpWoRAr3uCxFi/?fxhash=ooab9m7Wxu4VkYqVktapnCJiH8k3mjLdixBbzvBjnYM1pByMP1U&fxchain=TEZOS&fxiteration=50&fxminter=tz1AwUmXXHJXGCh4xe5dFjxydHW1pLZ6kPqe',
  //     'https://gateway.fxhash2.xyz/ipfs/QmQqtmLz4KpjzL6hMatRzgEv9DEmd73L9XpWoRAr3uCxFi/?fxhash=opBaogeRrVKmj4Md2Du4fRbZYcfTpEvR6hTKSYFyPidEcMEJXT9&fxchain=TEZOS&fxiteration=32&fxminter=tz1UZGPJtRLsNyYUuYyQU9DSepSF13XxYxFk&fxwalletsowner=tz1SR5dqLZLUTWVtRMEhE2hovoos2Gb7bmPh',
  //     'https://gateway.fxhash2.xyz/ipfs/QmQqtmLz4KpjzL6hMatRzgEv9DEmd73L9XpWoRAr3uCxFi/?fxhash=oo9PDg2Zj4HoYbaCjg9CzYJyq2wZVH6chp8gLQ7kKTnNgQDEb1B&fxchain=TEZOS',
  //     'https://gateway.fxhash2.xyz/ipfs/QmQqtmLz4KpjzL6hMatRzgEv9DEmd73L9XpWoRAr3uCxFi/?fxhash=oopwR31eu1oUbBBwkSiKAFJE5LQpRVKpb15eePnhvjqpSD9uhp2&fxchain=TEZOS&fxiteration=22&fxminter=tz1h9YYAUHSBk1J7RYhTqzuLwAxp8eAXaJkk',
  //     'https://gateway.fxhash2.xyz/ipfs/QmQqtmLz4KpjzL6hMatRzgEv9DEmd73L9XpWoRAr3uCxFi/?fxhash=ooppN5ptptA1V8YJUziSSP8kL35DnjkWycYTUGWA5SgRLnK5Bot&fxchain=TEZOS&fxiteration=49&fxminter=tz1gogLgnpRNKLeu8fEvzFXjSVR2VJAQ5Jo1',
  //     'https://gateway.fxhash2.xyz/ipfs/QmQqtmLz4KpjzL6hMatRzgEv9DEmd73L9XpWoRAr3uCxFi/?fxhash=ooKzyGSQwtoq2ZQuJ3LaXnuz3ixv1vHJhwo48Sn52ZnnvNgXTvW&fxchain=TEZOS&fxiteration=34&fxminter=tz1BRDxmjsC9dri4yqi5G6LPeG7WrXW1L4K5',
  //   ]
  // },
  {
    title: "öresund", link: [
      'https://gateway.fxhash2.xyz/ipfs/QmQbQBEpybPExHLW6TS39UPxk5C2B1BbEr8o2yJEfHrLQL/?fxhash=oonNzdoN5Y5aFALenu8oBwULqrYmLM75TVtrD77ZrSmcu5vyoCK&fxchain=TEZOS',
      'https://gateway.fxhash2.xyz/ipfs/QmQbQBEpybPExHLW6TS39UPxk5C2B1BbEr8o2yJEfHrLQL/?fxhash=opPLNkGajXVWiYG7abLgpCbkaeGtdt7CCC3Xq1cpbdfJnr7ksQs&fxchain=TEZOS&fxiteration=13&fxminter=tz1NeGFhrALF6nbFnfVCJSU9dJwg6Rk5CiBs&fxwalletsowner=tz1NeGFhrALF6nbFnfVCJSU9dJwg6Rk5CiBs',
      'https://gateway.fxhash2.xyz/ipfs/QmQbQBEpybPExHLW6TS39UPxk5C2B1BbEr8o2yJEfHrLQL/?fxhash=onwLHcRPHP6D9QzKbMSrrthCQSWVJFJA9Vs3ykMzvxJJrXpFNB2&fxchain=TEZOS&fxiteration=2&fxminter=tz1he2B6L9MqwUrXPysHro7E5GN1QosVLMad&fxwalletsowner=tz1he2B6L9MqwUrXPysHro7E5GN1QosVLMad&fxwalletsowner=0xC6Dd8fC985F028e7E8A601fD40B1Fd36b4e502de',
      'https://gateway.fxhash2.xyz/ipfs/QmQbQBEpybPExHLW6TS39UPxk5C2B1BbEr8o2yJEfHrLQL/?fxhash=opa6e4U46R8Jkyi9rwZAxx5H8b19wAtWG6FQTzeHQigNvJPmMY8&fxchain=TEZOS&fxiteration=74&fxminter=tz1he2B6L9MqwUrXPysHro7E5GN1QosVLMad&fxwalletsowner=tz1he2B6L9MqwUrXPysHro7E5GN1QosVLMad&fxwalletsowner=0xC6Dd8fC985F028e7E8A601fD40B1Fd36b4e502de',
      'https://gateway.fxhash2.xyz/ipfs/QmQbQBEpybPExHLW6TS39UPxk5C2B1BbEr8o2yJEfHrLQL/?fxhash=opBK2qCi7ibfcQjmSzYE6v9rBzPgHenuwnTjH9huLhvR5H9h9di&fxchain=TEZOS&fxiteration=36&fxminter=tz1bmPatnVaBa8brRzQ15i4vWzcBtdwJAkbn&fxwalletsowner=tz1bmPatnVaBa8brRzQ15i4vWzcBtdwJAkbn&fxwalletsowner=0xed2647390b6FED5eC4e07E8036101A191f3eE4AB',
      'https://gateway.fxhash2.xyz/ipfs/QmQbQBEpybPExHLW6TS39UPxk5C2B1BbEr8o2yJEfHrLQL/?fxhash=ono2FuhDbi9uyorrZaJ5dtCAxkAB727HPAyJQsRXh78AUH43nqe&fxchain=TEZOS&fxiteration=197&fxminter=tz1Y1qNfDq6SUb1bPYGgNAjaibnPgYXkF3x1&fxwalletsowner=tz1WjfhjJxAKViXNe8RjEXabfJojgPqssBZg',
      'https://gateway.fxhash2.xyz/ipfs/QmQbQBEpybPExHLW6TS39UPxk5C2B1BbEr8o2yJEfHrLQL/?fxhash=opX3XgEu9gt25kyVNXB315LwWT51h1NYSP2bT66nqpg5gsk2WQN&fxchain=TEZOS&fxiteration=237&fxminter=tz1UJ3k1mbYeNm9e1PyuFaSt597zuYPv79Ec&fxwalletsowner=tz1UJ3k1mbYeNm9e1PyuFaSt597zuYPv79Ec',
      'https://gateway.fxhash2.xyz/ipfs/QmQbQBEpybPExHLW6TS39UPxk5C2B1BbEr8o2yJEfHrLQL/?fxhash=opF9aevdpwxcSAxAtvCzEqfn2nEwwDhK84fPciRnhvmWBxC4LZ8&fxchain=TEZOS&fxiteration=201&fxminter=tz2GRzd2coKiwbFWN9eauk7b1Raewa4Ebw7V&fxwalletsowner=tz29ZXoiPR6j6gNkbiuQgQ5KRaPqKCnAuF4A',
      'https://gateway.fxhash2.xyz/ipfs/QmQbQBEpybPExHLW6TS39UPxk5C2B1BbEr8o2yJEfHrLQL/?fxhash=op9HnzMmyEo5NQDwJ5GwYd6ir1FeobZfmx4jh6YhmzUSRTzrKpD&fxchain=TEZOS&fxiteration=192&fxminter=tz1QaiiYsKv24ht4wmXg6yba8w6VE5qAADQS&fxwalletsowner=tz2Mx7wzCigV6EFfiwptXzxRyPmAVEjfhZus&fxwalletsowner=0x1b598D8Bfb465de423451Fc18d570c0B0A29c9C8',
    ]
  },
  // {
  //   title: "alphabits", link: [
  //     'https://gateway.fxhash2.xyz/ipfs/QmNWhEM1C6Cj1LZvdBGpxwpw7VRPXakZQKLFHRXWgN6c6H/?fxhash=ooqM5HZS6fsYDWaE8aQRodK1rXiKSwGzEMfb753zq99ySB3fPBH&fxchain=TEZOS&fxiteration=7&fxminter=tz1TG9d5ACUTVsyASTP2yi412VbW8hf2HUV6&fxwalletsowner=tz1gyCodjdnp5Tg7QtYQcFUXpY5ggtTHivWm&fxwalletsowner=0x891872283C926fEB6cf2fc40c07004F46437fb96',
  //     'https://gateway.fxhash2.xyz/ipfs/QmNWhEM1C6Cj1LZvdBGpxwpw7VRPXakZQKLFHRXWgN6c6H/?fxhash=ooKvpXiAVYFf5vrSxiYmX9i1VjBbgjAX78DBw3vNs1iqHVF6f3L&fxchain=TEZOS',
  //     'https://gateway.fxhash2.xyz/ipfs/QmNWhEM1C6Cj1LZvdBGpxwpw7VRPXakZQKLFHRXWgN6c6H/?fxhash=oog8P1MQwmGKxq1j5ntJATbTFAKebueWnxDWCYg6A24Nr6sAiVX&fxchain=TEZOS&fxiteration=10&fxminter=tz1ZRjuE2STA75rzXr78GcXNUZhYubwxaTYd&fxwalletsowner=tz2Dz4kN8HVrkBDrYJEWq4JyZkzBUZUrPN3p&fxwalletsowner=0xd7b064F257428e7B0d5f6216BC31EcDebdCCad62',
  //     'https://gateway.fxhash2.xyz/ipfs/QmNWhEM1C6Cj1LZvdBGpxwpw7VRPXakZQKLFHRXWgN6c6H/?fxhash=oo5SW5JysVavXdpHhCyVzJyAFKNatj5G5YD2S5H1rb1gf91mbWg&fxchain=TEZOS&fxiteration=12&fxminter=tz1bmPatnVaBa8brRzQ15i4vWzcBtdwJAkbn&fxwalletsowner=tz1bv9V6dLGRwx2u6iF8NvmXFgLs9ksErrZs&fxwalletsowner=0xc3Cc969750b2337FFd08c056349Dd0416228ADC9',
  //     'https://gateway.fxhash2.xyz/ipfs/QmNWhEM1C6Cj1LZvdBGpxwpw7VRPXakZQKLFHRXWgN6c6H/?fxhash=oo8chtKZ3bZxZCBKvF1Hm2V3Vhzcr2ZBtCozTdi1ntGvLiKxY1d&fxchain=TEZOS&fxiteration=40&fxminter=tz1LjTDCzYd2oP3LxdTnHeavwVHvs6UU2de8&fxwalletsowner=tz1W1GYYv7Lmb4L6SNXv7D3nptnRygoA6AUr',
  //     'https://gateway.fxhash2.xyz/ipfs/QmNWhEM1C6Cj1LZvdBGpxwpw7VRPXakZQKLFHRXWgN6c6H/?fxhash=opKmhDSYrszek4FK3sJSpcUNqYVjGLgWkzChNzgLC4QmXkPZJXJ&fxchain=TEZOS&fxiteration=59&fxminter=tz1Y7HdTpFBMfsbkU8CAb2MVGYeb1RgshSgw&fxwalletsowner=tz1LsKkwJhESqhfVVKkdkU2xgNxJVrnouoeJ',
  //     'https://gateway.fxhash2.xyz/ipfs/QmNWhEM1C6Cj1LZvdBGpxwpw7VRPXakZQKLFHRXWgN6c6H/?fxhash=oohZaBon6s85sFKnU7XHWHK5Hcs8qNjetYzRB4JFvbyFwLkzSpc&fxchain=TEZOS&fxiteration=75&fxminter=tz1hsYqjyc3CNaPA6P9xsa1BdndRt5Uq8tz1&fxwalletsowner=tz1RxdMQhHoQKxmtwqEC8h5yLHVghHdEPqEk',
  //     'https://gateway.fxhash2.xyz/ipfs/QmNWhEM1C6Cj1LZvdBGpxwpw7VRPXakZQKLFHRXWgN6c6H/?fxhash=opDvpn3pqbjN8R9BfKnUddf4nuuqVqvYuiBZAArpZ3StZ1YH5Gm&fxchain=TEZOS&fxiteration=93&fxminter=tz1fkfvhPGJiodqB3XMrnJxsFpaDsdfjp7jg&fxwalletsowner=tz1eRRvXVr96V52udzobdP6ydy67msfJjuHH&fxwalletsowner=0x47E2BC7475ef8a9a5e10aeF076da53d3C446291e',
  //     'https://gateway.fxhash2.xyz/ipfs/QmNWhEM1C6Cj1LZvdBGpxwpw7VRPXakZQKLFHRXWgN6c6H/?fxhash=onvYwd7rDDoKdrKkkuDg5YNuZAXx4RDyucoiy9qvkyCocYXBvm6&fxchain=TEZOS&fxiteration=121&fxminter=tz1a2aHDQayeSEsbDRm5igEiLYH4cxcNnKyz&fxwalletsowner=tz1a2aHDQayeSEsbDRm5igEiLYH4cxcNnKyz',
  //   ]
  // },
  // {
  //   title: "boca vista", link: [
  //     'https://gateway.fxhash2.xyz/ipfs/QmRqH1hU9DZhwCsXCy8UCHSWCPzKrn1HVBzuxKdmtpHR2c/?fxhash=ooxLsiNxvjnLe79QnvYgv7ggDC5wteUgR6Qfg7eeA7HwuLb87bQ&fxchain=TEZOS',
  //     'https://gateway.fxhash2.xyz/ipfs/QmRqH1hU9DZhwCsXCy8UCHSWCPzKrn1HVBzuxKdmtpHR2c/?fxhash=oo8LJR8mTRCRTC3r8TnuBmETB3YrsFvyAEcd8UuNnGZuvgPWsSJ&fxchain=TEZOS&fxiteration=58&fxminter=tz28UHkVhvdX3GzgpN6ugKGjQHS4PJyXnpF6&fxwalletsowner=tz1aHAsDofQd9KQb9ZsFZUzAsu39tJ2JrmKD',
  //     'https://gateway.fxhash2.xyz/ipfs/QmRqH1hU9DZhwCsXCy8UCHSWCPzKrn1HVBzuxKdmtpHR2c/?fxhash=oo6txfMV92oKf3J5uQKEA9XGxxhY1s3UfMK9n5vufWWgA9hqaMW&fxchain=TEZOS&fxiteration=4&fxminter=tz1br2FcKq1oRz78SR67jFMKvSZ1ST74zV6J',
  //     'https://gateway.fxhash2.xyz/ipfs/QmRqH1hU9DZhwCsXCy8UCHSWCPzKrn1HVBzuxKdmtpHR2c/?fxhash=opR7rNVLngxq1PijPTTimEMeBnFpF3rguJTfQaH9cGtKVBp6chH&fxchain=TEZOS&fxiteration=13&fxminter=tz1b2eVt7xm1H2giGTWaNeG2uJ85doKidsT7&fxwalletsowner=tz1Pz8bcLNsunzVzgDbALaze65N52LCTTqqK&fxwalletsowner=0x35ec3A7a69E21bf1D6F7dB4b0db7022667ED228d',
  //     'https://gateway.fxhash2.xyz/ipfs/QmRqH1hU9DZhwCsXCy8UCHSWCPzKrn1HVBzuxKdmtpHR2c/?fxhash=oniWfSqn8xzBHHky9XisLzhh78ZY5JKgBLjQgAvSjx7TstGZCTm&fxchain=TEZOS&fxiteration=41&fxminter=tz1fPnqswm41oEHUWdSDn3KSvq3KCXVNeFCj&fxwalletsowner=tz1fPnqswm41oEHUWdSDn3KSvq3KCXVNeFCj&fxwalletsowner=0x27E832f46148e089d1529D1bA5087dbF1cCc29f2',
  //     'https://gateway.fxhash2.xyz/ipfs/QmRqH1hU9DZhwCsXCy8UCHSWCPzKrn1HVBzuxKdmtpHR2c/?fxhash=oniWfSqn8xzBHHky9XisLzhh78ZY5JKgBLjQgAvSjx7TstGZCTm&fxchain=TEZOS&fxiteration=41&fxminter=tz1fPnqswm41oEHUWdSDn3KSvq3KCXVNeFCj&fxwalletsowner=tz1fPnqswm41oEHUWdSDn3KSvq3KCXVNeFCj&fxwalletsowner=0x27E832f46148e089d1529D1bA5087dbF1cCc29f2',
  //   ]
  // },
  {
    title: "akira2", link: [
      'https://cache.teia.rocks/ipfs/QmNcdo7j2ZU7cpJLEnxGknkHfMnL7oqgLvFw6KGhYbbiE3/?creator=tz1he2B6L9MqwUrXPysHro7E5GN1QosVLMad&viewer=tz1he2B6L9MqwUrXPysHro7E5GN1QosVLMad&objkt=497148',
    ]
  },
  {
    title: "akira", link: [
      'https://cache.teia.rocks/ipfs/QmUDQJoxrrgZ38opLq6szoG5PGtUsewLcXkkAxLrXGCpX9/?creator=tz1he2B6L9MqwUrXPysHro7E5GN1QosVLMad&viewer=tz1he2B6L9MqwUrXPysHro7E5GN1QosVLMad&objkt=493214',
    ]
  },
  // {
  //   title: "reich", link: [
  //     'https://cache.teia.rocks/ipfs/QmTqus3NBFjcDUjLVe6YPY612MKpUBxwBgGfhRTPYmcNWv/?creator=tz1he2B6L9MqwUrXPysHro7E5GN1QosVLMad&viewer=tz1he2B6L9MqwUrXPysHro7E5GN1QosVLMad&objkt=477275'
  //   ]
  // },
  {
    title: "yoyo", link: [
      'https://cache.teia.rocks/ipfs/Qmb9doLV5hX41x91CXdMVB4xxvzc3JCnD9joGjWsqEUakX/?creator=tz1he2B6L9MqwUrXPysHro7E5GN1QosVLMad&viewer=tz1he2B6L9MqwUrXPysHro7E5GN1QosVLMad&objkt=473786'
    ]
  },
  {
    title: "honeybee inn", link: [
      'https://cache.teia.rocks/ipfs/QmZf5Ye7iFaykJ1M8sKGbKhDYd8iYqThho6rcU5vvMYkWL/?creator=tz1he2B6L9MqwUrXPysHro7E5GN1QosVLMad&viewer=tz1he2B6L9MqwUrXPysHro7E5GN1QosVLMad&objkt=468471'
    ]
  },
  {
    title: "la neta", link: [
      'https://cache.teia.rocks/ipfs/QmSYCE3mzVNBEctqTYrbCERcgLM13GCWCx1FoA4Xfo9mFr/?creator=tz1he2B6L9MqwUrXPysHro7E5GN1QosVLMad&viewer=tz1he2B6L9MqwUrXPysHro7E5GN1QosVLMad&objkt=411549'
    ]
  },
  // {
  //   title: "window", link: [
  //     'https://cache.teia.rocks/ipfs/QmfTZ66qFwoGpegyctJ9ZA8tEP26WiALuXEAJVisHuHKKA/?creator=tz1he2B6L9MqwUrXPysHro7E5GN1QosVLMad&viewer=tz1he2B6L9MqwUrXPysHro7E5GN1QosVLMad&objkt=86418'
  //   ]
  // },
  // { title: "spaceloop", link: ['/spaceloop'] },
  { title: "ramjam", link: ['/ramjam/sketches/2d/'] },
]
let visited = localStorage.getItem("visited") ? JSON.parse(localStorage.getItem("visited")) : null

if (!visited) {
  visited = {}
  links.forEach((l) => {
    visited[l.title] = false
  })
}


function App() {
  const [key, setKey] = useState()
  let startTitle = '🍒'
  let email = 'webmaster@rami.lol'
  
  const [title, setTitle] = useState(startTitle)
  const [flag, setFlag] = useState(true)
  useEffect(() => {
    setInterval(
      () => {
        if(flag){
          let k = randI(100000)
          setKey(k)
          setFlag(false)
          console.log('hej')
        }
      }, 500
    )
  }, [])
  return (
    <div>
      <a className="title" onClick={() => setTitle(title === startTitle ? email : startTitle)}>{title}</a>
      <div class="links" key={key}>
        {links.map((l, i) =>
          <div className={"link" + (visited[l.title] ? ' strike' : '')} onClick={() => {
            visited[l.title] = true
            localStorage.setItem('visited', JSON.stringify(visited))
            window.location = rand(l.link)
            // console.log(l.link)
          }
          }>
            {l.title}
          </div>
        )}
      </div>
      {/* <a className="title" href="mailto:ramioso@gmail.com">jams rams 🍒</a> */}
    </div>
  );
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
